import getConfig from 'next/config'

import { ISeoTagsFields } from '../../../../../contentful-types'
import { Language } from '../../../locale'

interface MetaDataMetaTag {
  property?: string
  name?: string
  content: string
}

interface MetaDataLinkTag {
  rel: string
  href: string
}

interface MetaData {
  meta?: MetaDataMetaTag[]
  link?: MetaDataLinkTag[]
}

export function generateCanonicalUrl(language: Language, slug: string): string | null {
  if (!slug) {
    return ''
  }

  const isProd = getConfig()?.publicRuntimeConfig?.IS_PROD

  if (isProd) {
    return `https://www.telus.com/${language}/${slug === '/' ? '' : slug}`
  }

  return ''
}

export function generateSEOMetadata(
  seo: ISeoTagsFields,
  canonicalUrl: string,
  ogImageUrl: string,
  seoTitle: string
): MetaDataMetaTag[] {
  if (!seo || Object.keys(seo).length === 0) {
    return []
  }

  const title = seoTitle ? seoTitle : 'TELUS.com'

  return [
    {
      property: 'og:site_name',
      content: seo?.siteName ? seo?.siteName : 'TELUS',
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:title',
      content: seo?.ogTitle ? seo?.ogTitle : title,
    },
    {
      property: 'og:description',
      content: seo?.ogDescription ? seo?.ogDescription : seo?.description,
    },
    {
      property: 'og:url',
      content: seo?.ogUrl ? seo?.ogUrl : canonicalUrl,
    },
    {
      property: 'og:image',
      content: ogImageUrl,
    },
    ...(seo?.noIndex
      ? [
          {
            name: 'robots',
            content: 'noindex',
          },
          {
            name: 'googlebot',
            content: 'noindex',
          },
        ]
      : []),
  ]
}

export function generateCustomMetaTags(seo: ISeoTagsFields): MetaDataMetaTag[] {
  if (!seo || !seo.metaTags) {
    return []
  }
  return seo.metaTags.map(({ fields: metaTag }) => ({
    name: metaTag?.nameAttribute,
    content: metaTag?.contentAttribute,
  }))
}

export function getSEOMetadata(seo: ISeoTagsFields, language: Language, slug: string, title: string): MetaData {
  const canonicalUrl = seo?.canonicalUrl || generateCanonicalUrl(language, slug)
  const ogImageUrl =
    seo?.ogImage && seo?.ogImage.fields?.file && seo?.ogImage.fields.file.url && `https:${seo.ogImage.fields.file.url}`
  const applicationMeta: MetaDataMetaTag = { name: 'application', content: 'site-builder-v2' }
  const meta: MetaDataMetaTag[] = [
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1.0',
    },
    {
      name: 'description',
      content: seo?.description,
    },
  ]
  if (seo?.keywords) {
    meta.push({
      name: 'keywords',
      content: seo?.keywords?.join(', '),
    })
  }

  meta.push(...generateSEOMetadata(seo, canonicalUrl, ogImageUrl, title))
  meta.push(...generateCustomMetaTags(seo))
  meta.push(applicationMeta)

  const link = []
  if (canonicalUrl) {
    link.push({ rel: 'canonical', href: canonicalUrl })
  }

  return {
    meta,
    link,
  }
}
