import { GlobalElementsConfig, LineOfBusinessConfig, PageBrand } from '../types'

const geConfig: GlobalElementsConfig = {
  header: 'multibrand',
  footer: 'multibrand',
}

const pageBrand: PageBrand = 'consumer'

export const multibrandHeaderFooter = new LineOfBusinessConfig(geConfig, pageBrand)
